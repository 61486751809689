export const URL = {
  HOME: "/",
  ABOUT: "/#about",
  CONTACT: "/#contact",
  DEALS: "/#deals",
  SERVICE: "/#service",
}

export const SOCIAL = {
  INSTAGRAM: "https://www.instagram.com/smartsourcingsolutions",
  LINKEDIN: "https://www.linkedin.com/company/smart-sourcing-solutions",
  PRIVACY: "/documents/Privacy Policy Smart Sourcing Solutions B.V..pdf",
  TERMS: "/documents/Terms of Service Smart Sourcing Solutions B.V..pdf",
}

export const EMAIL = {
  username: "contact@smartsourcingsolutions.nl",
  password: "78544A61F6DC2601EBB3F1077E7E4F109C9C",
  server: "smtp.elasticemail.com",
  port: "2525",
  api: "D0A860D2AFC65A890F5A03F373AEDABE690D3AD6BD855F775ECF591647E01D2A88317A2DF1E5BF1FED2B3136DF2939F7",
}
