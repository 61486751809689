import { ReactComponent as Logo } from "assets/img/logo.svg"
import NavLink from "components/nav/NavLink"
import NavLinkMobile from "components/nav/NavLinkMobile"
import { useProps } from "contexts/PropsContext"
import { URL } from "libs/constants"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaBars } from "react-icons/fa"
import { Link } from "react-router-dom"

const Nav = () => {
  const { t } = useTranslation(["landing"])

  const { setLanguage } = useProps()

  const [open, setOpen] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      <div
        className={`bg-white transition fixed top-0 left-0 right-0 z-40 px-30 md:px-40 xl:px-60 flex items-center justify-center ${
          scrollPosition > 0 || open ? "h-80" : "h-80 xl:h-[140px]"
        }`}
      >
        <div className="w-full max-w-screen-xl flex justify-between items-center">
          <Link to={URL.HOME} className="">
            <Logo
              className={`${
                scrollPosition > 0 || open
                  ? "max-w-[90px] max-h-[40px]"
                  : "max-w-[90px] max-h-[40px] xl:max-w-[130px] xl:max-h-[58px]"
              }`}
            />
          </Link>
          <div className="flex items-center gap-20 xl:gap-40">
            <div className="hidden lg:flex items-center gap-20 xl:gap-30">
              <NavLink link={URL.HOME} title={t("nav-home")} scrollPosition={scrollPosition} />
              <NavLink link={URL.SERVICE} title={t("nav-service")} scrollPosition={scrollPosition} />
              <NavLink link={URL.ABOUT} title={t("nav-about")} scrollPosition={scrollPosition} />
              <NavLink link={URL.DEALS} title={t("nav-deals")} scrollPosition={scrollPosition} />
              <NavLink link={URL.CONTACT} title={t("nav-contact")} scrollPosition={scrollPosition} />
            </div>
            <div className="flex items-center gap-12">
              <button onClick={() => setLanguage("nl")} className="text-16 font-semibold text-blue">
                NL
              </button>
              <button onClick={() => setLanguage("en")} className="text-16 font-semibold text-blue">
                EN
              </button>
            </div>
          </div>
          <div className="flex lg:hidden w-80 h-80 relative">
            <button onClick={() => setOpen(!open)} className="flex w-80 h-80 relative items-center justify-center">
              <FaBars className="text-24 text-blue" />
            </button>
            {open ? (
              <div className="fixed top-80 left-0 p-20 pt-10 sm:p-30 sm:pt-30 flex flex-wrap justify-center gap-12 bg-white w-screen min-w-[300px]">
                <NavLinkMobile link={URL.HOME} title={t("nav-home")} />
                <NavLinkMobile link={URL.SERVICE} title={t("nav-service")} />
                <NavLinkMobile link={URL.ABOUT} title={t("nav-about")} />
                <NavLinkMobile link={URL.DEALS} title={t("nav-deals")} />
                <NavLinkMobile link={URL.CONTACT} title={t("nav-contact")} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Nav
