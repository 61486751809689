import axios from "axios"
import FooterArea from "components/footer/FooterArea"
import FooterInput from "components/footer/FooterInput"
import WrapperBlockSmall from "components/wrapper/WrapperBlockSmall"
import { init } from "emailjs-com"
import { EMAIL, SOCIAL } from "libs/constants"
import { openLink, validateEmail } from "libs/functions"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { FaInstagram, FaLinkedin } from "react-icons/fa"
import { toast } from "react-toastify"

const Footer = () => {
  const { t } = useTranslation(["landing"])

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const [email2, setEmail2] = useState("")

  useEffect(() => {
    init("user_dHG1d9p69iUaQyG8zhZab")
  }, [])

  const onSubmit = () => {
    if (!validateEmail(email)) {
      return toast.error("Enter a valid email address!")
    }

    if (!(name && email && message)) {
      return toast.error("Complete the entire form!")
    }

    axios({
      url: `https://api.elasticemail.com/v2/email/send?apikey=${EMAIL.api}&subject=SmartSourcingSolution Contact Form&from=${EMAIL.username}&fromName=Smart Sourcing Solutions Contact&to=${EMAIL.username}&bodyText=New message from ${name}, ${email}: ${message}`,
      method: "post",
      data: {},
      headers: {},
    })
      .then((res) => res.data)
      .then((res: any) => {
        if (res.success) {
          setName("")
          setEmail("")
          setMessage("")

          toast.success("Successfully sent your message!")
        } else {
          toast.error("An error occured sending your message!")
        }
      })
      .catch(() => {
        toast.error("An error occured sending your message!")
      })
  }

  const onNewsPaper = () => {
    if (!validateEmail(email2)) {
      return toast.error("Enter a valid email address!")
    }

    if (!email2) {
      return toast.error("Complete the entire form!")
    }

    axios({
      url: `https://api.elasticemail.com/v2/email/send?apikey=${EMAIL.api}&subject=SmartSourcingSolution Newspaper&from=${EMAIL.username}&fromName=Smart Sourcing Solutions Newspaper&to=${EMAIL.username}&bodyText=Newspaper signup from: ${email2}`,
      method: "post",
      data: {},
      headers: {},
    })
      .then((res) => res.data)
      .then((res: any) => {
        if (res.success) {
          setEmail2("")

          toast.success("Successfully sent your message!")
        } else {
          toast.error("An error occured sending your message!")
        }
      })
      .catch(() => {
        toast.error("An error occured sending your message!")
      })
  }

  return (
    <>
      <div id="contact" className="w-full flex justify-center p-30 md:p-40 xl:p-60 bg-blue">
        <div className="w-full max-w-screen-xl">
          <div className="w-full grid grid-cols-1 xl:grid-cols-[1fr,auto] gap-50 md:gap-70 xl:gap-80">
            <div className="w-full">
              <div className="w-full text-[32px] md:text-[40px] font-bold text-white mb-10">{t("footer-title")}</div>
              <div className="w-full grid grid-cols-1 gap-30 mb-40">
                <div className="w-full grid grid-cols-1 md:grid-cols-[1fr,2fr] gap-30">
                  <FooterInput title={t("footer-name")} value={name} handler={setName} />
                  <FooterInput title={t("footer-email")} value={email} handler={setEmail} />
                </div>
                <FooterArea title={t("footer-message")} value={message} handler={setMessage} />
              </div>
              <div className="w-full flex">
                <button
                  onClick={() => onSubmit()}
                  className="w-max-full bg-blue-light px-24 xl:px-40 h-50 xl:h-70 rounded-full font-semibold flex items-center text-white text-16 xl:text-20"
                >
                  {t("footer-send")}
                </button>
              </div>
            </div>
            <div className="w-full xl:w-[350px]">
              <div className="w-full mb-40">
                <div className="w-full text-[32px] md:text-[40px] font-bold text-white mb-10">{t("footer-news")}</div>
                <div className="w-full grid grid-cols-1 gap-30 mb-40">
                  <FooterInput title={t("footer-email")} value={email2} handler={setEmail2} />
                </div>
                <div className="w-full flex">
                  <button
                    onClick={() => onNewsPaper()}
                    className="w-max-full bg-blue-light px-24 xl:px-40 h-50 xl:h-70 rounded-full font-semibold flex items-center text-white text-16 xl:text-20"
                  >
                    {t("footer-submit")}
                  </button>
                </div>
              </div>
              <div className="w-full grid grid-cols-1 gap-40">
                <div className="w-full grid grid-col-1 gap-2">
                  <div className="w-full text-20 text-white font-bold">{t("footer-email")}</div>
                  <div className="w-full text-16 text-white">info@smartsourcingsolutions.nl</div>
                </div>
                <div className="w-full grid grid-col-1 gap-2">
                  <div className="w-full text-20 text-white font-bold">{t("footer-phone")}</div>
                  <div className="w-full text-16 text-white">+31 6 21525712</div>
                </div>
                <div className="w-full grid grid-col-1 gap-4">
                  <div className="w-full text-20 text-white font-bold">{t("footer-socials")}</div>
                  <div className="w-full flex items-center justify-start gap-12">
                    <button onClick={(e) => openLink(e, SOCIAL.INSTAGRAM)}>
                      <FaInstagram className="text-22 text-white" />
                    </button>
                    <button onClick={(e) => openLink(e, SOCIAL.LINKEDIN)}>
                      <FaLinkedin className="text-22 text-white" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-white">
        <WrapperBlockSmall>
          <div className="flex w-full items-center justify-center gap-20">
            <button onClick={(e) => openLink(e, SOCIAL.TERMS)} className="text-18 text-blue-dark">
              Terms of Service
            </button>
            <button onClick={(e) => openLink(e, SOCIAL.PRIVACY)} className="text-18 text-blue-dark">
              Privacy Statement
            </button>
          </div>
        </WrapperBlockSmall>
      </div>
    </>
  )
}

export default Footer
