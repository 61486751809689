import React from "react";
import { useTranslation } from "react-i18next";

const FooterInput = ({ title, value, handler }: { title: string; value: string; handler: any }) => {
  const { t } = useTranslation(["landing"]);

  return (
    <>
      <div className="w-full grid grid-cols-1">
        <div className="w-full text-20 text-white font-bold">{title}</div>
        <input
          type="text"
          className="w-full py-10 placeholder-white placeholder-opacity-50 text-white text-16 border-b-1 border-white"
          placeholder={`${t("footer-enter")} ${title.toLowerCase()} ${t("footer-here")}...`}
          value={value}
          onChange={(e) => handler(e.target.value)}
        />
      </div>
    </>
  );
};

export default FooterInput;
