import Services1 from "assets/img/services/1.png";
import Services2 from "assets/img/services/2.png";
import Services3 from "assets/img/services/3.png";
import React from "react";
import { useTranslation } from "react-i18next";

const HomeService = () => {
  const { t } = useTranslation(["landing"]);

  return (
    <>
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-30 md:gap-40 xl:gap-[70px]">
        <div className="w-full rounded-[30px] md:rounded-[40px] xl:rounded-[80px] px-30 grid grid-cols-1 gap-40 pt-30 md:pt-60 pb-30 md:pb-50 bg-blue">
          <div className="w-full flex justify-center">
            <img src={Services1} alt="" className="max-h-[100px] max-w-[130px]" />
          </div>
          <div className="w-full text-center text-16 text-white">{t("services-1")}</div>
        </div>
        <div className="w-full rounded-[30px] md:rounded-[40px] xl:rounded-[80px] px-30 grid grid-cols-1 gap-40 pt-30 md:pt-60 pb-30 md:pb-50 bg-blue-light-2">
          <div className="w-full flex justify-center">
            <img src={Services3} alt="" className="max-h-[100px] max-w-[130px]" />
          </div>
          <div className="w-full text-center text-16 text-blue">{t("services-2")}</div>
        </div>
        <div className="w-full rounded-[30px] md:rounded-[40px] xl:rounded-[80px] px-30 grid grid-cols-1 gap-40 pt-30 md:pt-60 pb-30 md:pb-50 bg-blue">
          <div className="w-full flex justify-center">
            <img src={Services2} alt="" className="max-h-[100px] max-w-[130px]" />
          </div>
          <div className="w-full text-center text-16 text-white">{t("services-3")}</div>
        </div>
      </div>
    </>
  );
};

export default HomeService;
