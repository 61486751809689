import HomeWho from "assets/img/home_who.png";
import React from "react";

const HomeTeamRight = ({ name, text, photo }: { name: string; text: string; photo: string }) => {
  return (
    <>
      <div
        id="about"
        className="w-full mb-50 md:mb-70 xl:mb-[135px] grid grid-cols-1 lg:grid-cols-[400px,1fr] gap-50 items-center"
      >
        <div className="w-full flex justify-center">
          <img
            src={photo ? photo : HomeWho}
            alt=""
            className="w-full sm:w-[400px] max-w-full rounded-[30px] md:rounded-[40px] xl:rounded-[100px]"
          />
        </div>
        <div className="w-full">
          <div className="w-full relative mb-[45px] h-[70px]">
            <div className="relative w-full leading-[70px] text-[90px] text-grey text-opacity-50 font-bold">{name}</div>
            <div className="absolute w-full leading-[32px] text-[40px] text-blue left-0 bottom-0 font-semibold">
              {name}
            </div>
          </div>
          <div className="text-16 text-blue mb-20 lg:mb-50">{text}</div>
        </div>
      </div>
    </>
  );
};

export default HomeTeamRight;
