import { URL } from "libs/constants"
import React from "react"
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom"
import ScrollToTop from "utils/ScrollToTop"
import Home from "views/Home"

const App = () => {
  return (
    <>
      <div className="w-full min-w-[300px]">
        <div className="fixed top-0 left-0 bottom-0 right-0 bg-background"></div>
        <div className="w-full relative overflow-hidden">
          <Router basename={process.env.PUBLIC_URL}>
            <ScrollToTop />
            <Switch>
              <Route exact path={URL.HOME}>
                <Home />
              </Route>
              <Route>
                <Redirect to={URL.HOME} />
              </Route>
            </Switch>
          </Router>
        </div>
      </div>
    </>
  )
}

export default App
