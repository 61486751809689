import HomeHelp from "assets/img/home_help.png"
import HomeTop from "assets/img/home_top.png"
import HomeWho from "assets/img/home_who.png"
import HomeTeamBram from "assets/img/team/bram.jpeg"
import HomeTeamLaurens from "assets/img/team/laurens.jpeg"
import HomeTeamMaikel from "assets/img/team/maikel.jpeg"
import HomeTeamMerel from "assets/img/team/merel.jpeg"
import HomeTeamRene from "assets/img/team/rene.jpeg"
import Footer from "components/footer/Footer"
import HomeService from "components/home/HomeService"
import HomeTeamLeft from "components/home/HomeTeamLeft"
import HomeTeamRight from "components/home/HomeTeamRight"
import HomeTitle from "components/home/HomeTitle"
import HomeWhy from "components/home/HomeWhy"
import Nav from "components/nav/Nav"
import { URL } from "libs/constants"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"
import { scroller as Scroller } from "react-scroll"

const Home = () => {
  const location = useLocation()

  const { t } = useTranslation(["landing"])

  useEffect(() => {
    if (location.hash) {
      Scroller.scrollTo(location.hash.replace("#", ""), { offset: -100 })
    }
  }, [location])

  return (
    <>
      <Nav />
      <div className="w-full pt-80">
        <div className="w-full relative h-[500px] xl:h-[700px] flex items-center justify-center p-60">
          <img src={HomeTop} alt="" className="absolute h-full left-[50%] transform translate-x-[-50%]" />
          <div className="relative w-[700px] max-w-full grid grid-cols-1 gap-20">
            <div className="w-full text-center text-white text-[32px] md:text-[50px] font-semibold">
              {t("home-title")}
            </div>
            <div className="w-full text-center text-white text-16 whitespace-pre-line">{t("home-text")}</div>
          </div>
        </div>
        <div className="w-full flex justify-center px-30 md:px-40 xl:px-60 pb-60">
          <div className="w-full max-w-screen-xl pt-40 xl:pt-80">
            <div className="hidden w-full gap-16 md:gap-24 flex-wrap items-center justify-center relative mb-60">
              <div className="px-34 h-[70px] rounded-full bg-blue-light text-16 md:text-20 font-semibold text-white flex items-center">
                REAL ESTATE
              </div>
              <div className="px-34 h-[70px] rounded-full bg-blue-med text-16 md:text-20 font-semibold text-white flex items-center">
                DISCOVER OUR SERVICES
              </div>
              <div className="px-34 h-[70px] rounded-full bg-blue text-16 md:text-20 font-semibold text-white flex items-center">
                WE ARE SMART SOURCING SOLUTIONS
              </div>
            </div>
            <div id="service" className="w-full mb-50 xl:mb-[70px]">
              <HomeTitle title={t("services-title")} text={t("services-text")} />
            </div>
            <div className="w-full mb-50 xl:mb-[70px]">
              <HomeService />
            </div>
            <div className="w-full flex justify-center mb-[100px]">
              <Link
                to={URL.SERVICE}
                className="w-max-full bg-blue px-24 xl:px-34 h-50 xl:h-70 rounded-full font-semibold flex items-center text-white text-16 xl:text-20"
              >
                {t("services-button")}
              </Link>
            </div>
            <div
              id="about"
              className="w-full mb-50 md:mb-70 xl:mb-[135px] grid grid-cols-1 lg:grid-cols-2 gap-50 items-center"
            >
              <div className="w-full">
                <div className="w-full relative mb-[45px] h-[70px]">
                  <div className="relative w-full leading-[70px] text-[90px] text-grey text-opacity-50 font-bold">
                    {t("about-title")}
                  </div>
                  <div className="absolute w-full leading-[32px] text-[40px] text-blue left-0 bottom-0 font-semibold">
                    {t("about-text")}
                  </div>
                </div>
                <div className="text-16 text-blue mb-20 lg:mb-50">{t("about-description")}</div>
                <div className="w-full flex">
                  <button className="w-max-full bg-blue px-24 xl:px-40 h-50 xl:h-70 rounded-full font-semibold flex items-center text-white text-16 xl:text-20">
                    {t("about-button")}
                  </button>
                </div>
              </div>
              <div className="w-full">
                <img src={HomeWho} alt="" className="w-full rounded-[30px] md:rounded-[40px] xl:rounded-[100px]" />
              </div>
            </div>
            <div id="deals" className="w-full mb-50 md:mb-70 xl:mb-[110px] relative py-[120px] xl:py-80 xl:pr-[150px]">
              <div className="flex xl:hidden absolute top-0 left-[-40px] right-[-40px] h-full">
                <img
                  src={HomeHelp}
                  alt=""
                  className="absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%] min-h-full"
                />
              </div>
              <div className="hidden xl:flex absolute top-[50%] transform translate-y-[-50%] translate-x-0 h-full right-0 rounded-r-full min-w-[1600px] overflow-hidden">
                <img src={HomeHelp} alt="" className="absolute top-0 left-0 w-full" />
              </div>
              <div className="w-full relative">
                <div className="w-full mb-20 xl:mb-[46px] text-[32px] xl:text-[60px] text-white font-bold">
                  {t("banner-title")}
                </div>
                <div className="w-full text-white text-14 xl:text-16">{t("banner-description")}</div>
                <div className="hidden w-full mt-30 xl:mt-50">
                  <button className="w-max-full bg-blue-light px-24 xl:px-40 h-50 xl:h-70 rounded-full font-semibold flex items-center text-white text-16 xl:text-20">
                    VIEW THE DEALS
                  </button>
                </div>
              </div>
            </div>
            <div className="w-full mb-50">
              <HomeTitle title={t("team-title")} text={t("team-text")} />
            </div>
            <HomeTeamLeft name={"Maikel"} text={t("team-maikel")} photo={HomeTeamMaikel} />
            <HomeTeamRight name={"Rene"} text={t("team-rene")} photo={HomeTeamRene} />
            <HomeTeamLeft name={"Laurens"} text={t("team-laurens")} photo={HomeTeamLaurens} />
            <HomeTeamRight name={"Bram"} text={t("team-bram")} photo={HomeTeamBram} />
            <HomeTeamLeft name={"Merel"} text={t("team-merel")} photo={HomeTeamMerel} />
            <div className="w-full mb-50 xl:mb-[70px]">
              <HomeTitle title={t("deals-title")} text={t("deals-text")} />
            </div>
            <div className="w-full">
              <HomeWhy />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Home
