import { getterLanguage, setterLanguage } from "libs/functions/cookies"
import i18n from "libs/i18n"
import React, { createContext, useEffect, useState } from "react"

export function createCtx<ContextType>() {
  const ctx = React.createContext<ContextType | undefined>(undefined)
  function useCtx() {
    const c = React.useContext(ctx)
    if (!c) throw new Error("useCtx must be inside a Provider with a value")
    return c
  }
  return [useCtx, ctx.Provider] as const
}

type PropsContextType = {
  language: string
  path: string
  setLanguage: any
  setPath: any
}

export const [useProps, CtxProvider] = createCtx<PropsContextType>()

export const PropsContext = createContext<PropsContextType | undefined>(undefined)

export const PropsProvider = ({ children }: { children: React.ReactNode }) => {
  const [language, setLanguage] = useState(getterLanguage())
  const [path, setPath] = useState(window.location.pathname)

  useEffect(() => {
    setterLanguage(language)

    i18n.changeLanguage(language)
  }, [language])

  return (
    <>
      <CtxProvider
        value={{
          language,
          path,
          setLanguage,
          setPath,
        }}
      >
        {children}
      </CtxProvider>
    </>
  )
}

export default PropsProvider
