import React from "react";
import { Link } from "react-router-dom";

const NavLinkMobile = ({ link, title }: { link: string; title: string }) => {
  return (
    <>
      <Link
        to={link}
        className="w-full md:w-auto text-14 h-30 px-10 flex items-center font-semibold text-blue justify-center"
      >
        {title}
      </Link>
    </>
  );
};

export default NavLinkMobile;
