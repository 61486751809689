import React from "react";
import { Link } from "react-router-dom";

const NavLink = ({ link, title, scrollPosition }: { link: string; title: string; scrollPosition: number }) => {
  return (
    <>
      <Link
        to={link}
        className={`text-16 h-80 flex items-center font-semibold text-blue ${
          scrollPosition > 0 || open ? "" : "xl:text-20"
        }`}
      >
        {title}
      </Link>
    </>
  );
};

export default NavLink;
