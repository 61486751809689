import App from "App"
import PropsProvider from "contexts/PropsContext"
import i18next from "i18next"
import React, { Suspense } from "react"
import { CookiesProvider } from "react-cookie"
import ReactDOM from "react-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import reportWebVitals from "reportWebVitals"

import "assets/styles/global.css"
import "assets/styles/index.css"

const main = async () => {
  i18next.init({
    fallbackLng: "nl",
    lng: "nl",
    debug: true,

    interpolation: {
      escapeValue: false,
    },

    ns: ["landing"],
    defaultNS: "landing",
  })

  ReactDOM.render(
    <React.StrictMode>
      <CookiesProvider>
        <PropsProvider>
          <Suspense fallback={<></>}>
            <App />
          </Suspense>
        </PropsProvider>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </CookiesProvider>
    </React.StrictMode>,
    document.getElementById("app")
  )
}

reportWebVitals()
main()
