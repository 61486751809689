import React from "react";
import { useTranslation } from "react-i18next";

const HomeWhy = () => {
  const { t } = useTranslation(["landing"]);

  return (
    <>
      <div className="w-full flex justify-center">
        <div className="md:w-[600px] max-w-full grid grid-cols-1 gap-20">
          <div className="w-full text-16 text-blue">{t("deals-1")}</div>
          <div className="w-full grid grid-cols-1 gap-10">
            <div className="w-full text-24 text-blue font-semibold">Buy-to-let</div>
            <div className="w-full text-16 text-blue">{t("deals-2")}</div>
          </div>
          <div className="w-full grid grid-cols-1 gap-10">
            <div className="w-full text-24 text-blue font-semibold">Flip</div>
            <div className="w-full text-16 text-blue">{t("deals-3")}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeWhy;
