export const clearStorage = () => {
  localStorage.clear();
};

export const removeStorage = (name: string) => {
  localStorage.removeItem(name);
};

export const getterLanguage = () => {
  return localStorage.getItem("language") || "";
};

export const setterLanguage = (language: string) => {
  localStorage.setItem("language", language);

  return Promise.resolve();
};
