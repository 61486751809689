import React from "react";

const HomeTitle = ({ title, text }: { title: string; text: string }) => {
  return (
    <>
      <div className="w-full relative">
        <div className="relative w-full text-center leading-[90px] sm:leading-[80px] md:leading-[70px] text-[52px] sm:text-[60px] md:text-[90px] text-grey text-opacity-50 font-bold">
          {title}
        </div>
        <div className="absolute w-full text-center leading-[32px] text-[40px] text-blue left-0 bottom-0 font-semibold">
          {text}
        </div>
      </div>
    </>
  );
};

export default HomeTitle;
